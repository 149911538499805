import axios from "axios";
import dayjs from "dayjs";
import { API_URL } from "./constants";

export const axiosHandler = async ({
  url,
  params,
  method = "GET",
  data,
  state,
  headers,
  customBaseURL,
}) => {
  if (!url) throw Error("NO_URL_SPECIFIED");
  if (!method) throw Error("NO_METHOD_SPECIFIED");
  const baseURL = customBaseURL || API_URL;
  const options = {
    url,
    baseURL,
    method,
    params,
    data,
    headers,
    crossDomain: true,
  };
  if (state) {
    const { accessToken } = state.auth;
    if (!accessToken) throw Error("TOKEN_REQUIRED");
    options["headers"] = {
      ...options["headers"],
      Authorization: `Bearer ${accessToken}`,
    };
  }
  try {
    const res = await axios(options);
    return res.data;
  } catch (error) {
    throw Error(error?.response?.data?.error || error);
  }
};

export const appendParamsToUrl = (params) =>
  Object.keys(params)
    .map(
      (key) => encodeURIComponent(key) + "=" + encodeURIComponent(params[key])
    )
    .join("&");

export const lowerFirstLetter = (string) => {
  return string?.charAt(0)?.toLowerCase() + string?.slice(1);
};

export const numberFormatter = (num) => {
  if (!num) return "1";
  var num_parts = num.toString().split(".");
  num_parts[0] = num_parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, ",");
  return num_parts.join(".");
};

export const wait = async (ms) => {
  return new Promise((resolve) => {
    setTimeout(resolve, ms);
  });
};

export const truncate = (str, n = 100) => {
  return str?.length > n ? str?.substr(0, n - 1) + "..." : str;
};

export const dateFormat = (date, format = "YYYY-MM-DD HH:mm:ss") =>
  date ? dayjs(date, format) : dayjs();

export const HyperlinkURLs = ({ article, classes }) => {
  const urlRegex =
    /\b(?:https?:\/\/)?(?:www\.)?([-a-zA-Z0-9@:%._\+~#=]{1,256}\.[a-zA-Z0-9()]{1,6})\b(?:[-a-zA-Z0-9@:%_\+.~#?&//=]*)?/g;

  const textWithHyperlinks = article.description.replace(urlRegex, (match) => {
    const url = match.startsWith("http") ? match : `https://${match}`; // Prepend 'https://' if missing
    return `<a href="${url}" target="_blank" rel="noopener noreferrer">${match}</a>`;
  });

  return (
    <p
      className={classes.subheading}
      dangerouslySetInnerHTML={{ __html: textWithHyperlinks }}
    />
  );
};

export const extractVideoId = (url) => {
  if (!url) return;
  const regex =
    /(?:https?:\/\/)?(?:www\.)?(?:youtube\.com\/(?:[^\/\n\s]+\/\S+\/|(?:v|e(?:mbed)?)\/|\S*?[?&]v=)|youtu\.be\/)([a-zA-Z0-9_-]{11})/;
  const match = url.match(regex);
  return match ? match[1] : null;
};

export const parseLocation = ({ streetAddress, city, state }) => {
  let location = null;
  if (
    streetAddress &&
    streetAddress != "undefined" &&
    streetAddress != undefined
  )
    location = streetAddress;
  if (city && city != "undefined" && city != undefined)
    location = location ? `${location}, ${city}` : city;
  if (state && state != "undefined" && state != undefined)
    location = location ? `${location}, ${state}` : state;
  return location;
};
