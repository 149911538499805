import React, { useContext, useEffect, useMemo } from "react";
import { ModalContext } from "../../Context/ModalContext";
import { makeStyles } from "@material-ui/core/styles";
import setStyles from "../../setStyles";
import { useLocation } from "react-router-dom";
import { contactTypes } from "../../lib/constants";
const ContactCard = ({
  id,
  name,
  team,
  title,
  phone,
  email,
  service,
  location,
  logoUrl,
  lat,
  lon,
  companyName,
  role,
  contact,
}) => {
  console.log("location", location);
  const classes = useStyles();
  const { showModalHandler, getContactInfo } = useContext(ModalContext);

  const location2 = useLocation();
  useEffect(() => {
    if (location2.hash === `#${id}`) infoHandler();
  }, []);

  const showPhone = useMemo(() => {
    if (!contact?.phone) return false;
    if (contact?.type !== contactTypes.LEAGUE) return false;
    return false;
  }, [contact?.phone, contact?.type]);

  const infoHandler = () => {
    showModalHandler();
    getContactInfo({
      ...contact,
      name,
      service,
      location,
      phone,
      email,
      lat,
      lon,
      companyName,
      role,
      type: contact?.type,
    });
  };

  const triggerEmail = () => (window.location.href = `mailto:${email}`);
  const triggerPhoneCall = () => (window.location.href = `tel:${phone}`);
  return (
    <div className={classes.cardContainer} id={id}>
      <div className={classes.topContainer}>
        <div>
          <h2 className={classes.h2}>{name}</h2>
          <span>
            <h4 className={classes.h4}>{title}</h4>
            <h4 className={classes.h4}>{team}</h4>
            <h4 className={classes.h4}>{companyName}</h4>
            <h4 className={classes.h4}>
              {contact?.services?.length > 3
                ? contact?.services?.slice(0, 2).join(", ") + " ..."
                : contact?.services?.join(", ")}
            </h4>
          </span>
        </div>
        <div>
          {logoUrl && (
            <img
              src={logoUrl}
              width="75"
              className={classes.logo}
              alt="logo-team"
            />
          )}
        </div>
      </div>

      <div
        className={classes.buttonsContainer1}
        style={{
          gridTemplateColumns: showPhone ? "1fr 1fr 1fr" : "1fr 1fr",
        }}
      >
        {showPhone && (
          <button onClick={triggerPhoneCall} className={classes.button}>
            Call
          </button>
        )}
        <button
          onClick={triggerEmail}
          className={classes.button}
          style={{
            borderLeft: "1px solid lightgrey",
            borderRight: "1px solid lightgrey",
          }}
        >
          Email
        </button>
        <button className={classes.button} onClick={infoHandler}>
          Info
        </button>
      </div>
    </div>
  );
};

export default ContactCard;

const useStyles = makeStyles((theme) => ({
  cardContainer: {
    marginTop: "20px",
    width: "300px",
    height: "220px",
    backgroundColor: "white",
    borderRadius: "6px",
    position: "relative",
    boxShadow: "1px 1px 5px grey",
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-between",
    overflow: "hidden",
    [theme.breakpoints.down("xs")]: {
      width: "100%",
    },
  },
  topContainer: {
    padding: "5px 15px",
    position: "relative",
    height: "100px",
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
  },
  logo: {
    right: 15,
    bottom: 10,
  },
  phoneIcon: {
    color: "#32C759",
  },
  h2: {
    ...setStyles.sofiaProSemiBold,
    fontSize: "22px",
    letterSpacing: "-0.14px",
  },
  h4: {
    color: "#A28C62",
    fontSize: "12px",
    ...setStyles.sofiaProBlack,
    letterSpacing: "0.07px",
  },
  pContainer: {
    marginTop: "25px",
  },
  p: {
    color: "#505255",
    fontSize: "14px",
    ...setStyles.sofiaProSemiBold,
    letterSpacing: "0.42px",
  },
  buttonsContainer1: {
    width: "100%",
    display: "grid",
    overflow: "hidden",
  },
  buttonsContainer2: {
    width: "100%",
    display: "grid",
    overflow: "hidden",
  },
  button: {
    width: "100%",
    height: "50px",
    border: "none",
    borderTop: "1px solid lightgrey",
    fontSize: "14px",
    color: "rgb(0,0,0,0.5)",
    ...setStyles.sofiaProSemiBold,
    [theme.breakpoints.down("xs")]: {
      width: "100%",
    },
  },
}));
